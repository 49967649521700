// export const COMETCHAT_CONSTANTS = {
//   APP_ID: "1922331c3fb93af5",
//   REGION: "eu",
//   AUTH_KEY: "ebc43605aa92cb976e5a3f4a88f6e65118c04d07",
// };

// export const COMETCHAT_CONSTANTS = {
//   APP_ID: "6013c21c617a4",
//   REGION: "us",
//   AUTH_KEY: "bc6f5aeee282cb3a5ae91dee554caa24d3394281",
// };
// export const COMETCHAT_CONSTANTS = {
//   APP_ID: "9516d1f2f3500",
//   REGION: "us",
//   AUTH_KEY: "36cb265a8887e8e38068c016dff0d2f8fcd3d7b6",
// };
export const COMETCHAT_CONSTANTS = {
  APP_ID: "222798433cfa83b6",
  REGION: "us",
  AUTH_KEY: "c2d711421df66b16af8583fc56da7ab1849934d6",
};
